import _ from 'lodash'
import React from 'react'
import type { ShoppingListItemResult } from '../data/ShoppingItemFragment'
import s from './ShoppingListArticle.module.sass'
import { ShoppingListItemDate } from './ShoppingListItemDate'

export function ShoppingListArticleByDate(props: { items: ShoppingListItemResult[] }) {
	const pendingItems = props.items.filter((item) => item.status === 'pending')

	const groupedItemsByDate = Object.entries(_.groupBy(pendingItems, 'dueDate'))

	return (
		<div>
			{groupedItemsByDate.map(([groupName, group]) => (
				<div key={groupName} className={s.Article}>
					{group.map((item: ShoppingListItemResult) => (
						<ShoppingListItemDate item={item} key={item.id} />
					))}
				</div>
			))}
		</div>
	)
}
