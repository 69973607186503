import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { AddItemForm } from '../app/components/AddItemForm'
import { Header } from '../app/components/Header'
import { ShoppingList } from '../app/components/ShoppingList'
import { DashboardView } from '../app/const/DashboardView'
import { FavouriteItemFragment } from '../app/data/FavouriteItemFragment'
import { ShoppingListFragment } from '../app/data/ShoppingListFragment'
import { SupplierFragment } from '../app/data/SupplierFragment'
import { Thunder } from '../generated/content'
import { serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	return (
		<div>
			<Head>
				<title>Goodlok Groceries</title>
				<meta name="description" content="Goodlok groceries" />
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<Header />
			<AddItemForm
				suppliers={props.dataFromContember.listSupplier}
				dataListItems={props.dataFromContember.listFavouriteItem}
			/>
			{props.dataFromContember.listShoppingList.map(
				(list) =>
					list.items.filter((item) => item.status === 'pending') && (
						<ShoppingList
							activeSupplier={props.supplier}
							suppliers={props.dataFromContember.listSupplier}
							key={list.id}
							items={props.dataFromContember.listShoppingList[0].items}
							view={props.view}
						/>
					)
			)}
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const supplier = String(context.query?.supplier ?? '')

	const view = supplier ? DashboardView.Supplier : DashboardView.Deadline

	const dataFromContember = await content.query({
		listShoppingList: [{}, ShoppingListFragment(view, supplier)],
		listSupplier: [{}, SupplierFragment()],
		listFavouriteItem: [{}, FavouriteItemFragment()],
	})

	return {
		props: {
			view,
			supplier,
			auth: true as const,
			dataFromContember,
			serverTime: new Date().toString(),
		},
	}
})
