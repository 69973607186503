import clsx from 'clsx'
import moment from 'moment'
import 'moment/locale/cs'
import NextImage from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { createFetcher } from '../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { checkDueDate } from '../assets/helpers/dueDateOptions'
import { useRefresh } from '../assets/helpers/useRefresh'
import type { ShoppingListItemResult } from '../data/ShoppingItemFragment'
import SvgCheckmark from '../svg/icons/Checkmark'
import SvgCross from '../svg/icons/Cross'
import s from './ShoppingListItem.module.sass'

const api = createFetcher<API>('/api/v1/[handler]')

export function ShoppingListItemDate(props: { item: ShoppingListItemResult }) {
	const refresh = useRefresh()

	const handlePurchased = async () => {
		const data = props.item
		try {
			await api('updateShoppingListItemPurchased', {
				id: data.id,
			})
			refresh()
		} catch (e) {
			console.log(e)
		}
	}

	const handleRemoved = async () => {
		const data = props.item
		try {
			await api('updateShoppingListItemRemoved', {
				id: data.id,
			})
			refresh()
		} catch (e) {
			console.log(e)
		}
	}

	const isDue = moment(props.item.dueDate).isBefore(moment())

	const [doubleCheckVisible, setDoubleCheckVisible] = useState(false)

	const doubleCheck = () => {
		setDoubleCheckVisible(true)
	}

	return (
		<>
			<span className={clsx(isDue && s.AlertItemDue, s.Extra)}>
				{props.item.dueDate && moment(props.item.dueDate).isValid() ? (
					<div>{checkDueDate(props.item.dueDate)}</div>
				) : (
					<div>Je to fuk</div>
				)}
			</span>
			<tr className={s.ItemWrapper}>
				<td className={s.ItemAttrs}>
					<div className={s.Title}>{props.item.title}</div>
				</td>

				<td className={s.ItemAttrs}>
					<div className={s.Amount}>{props.item.amount}</div>
				</td>

				<td className={s.ItemSupplier}>
					{props.item.supplier ? (
						props.item.supplier.logo ? (
							<Link href={{ pathname: '/', query: { supplier: props.item.supplier.id } }} passHref>
								<a className={props.item.supplier.contact && s.LogoWrapper}>
									<NextImage
										className={s.Logo}
										key={props.item.supplier.logo.url}
										src={props.item.supplier.logo.url}
										width={66}
										height={28}
									/>
								</a>
							</Link>
						) : (
							<div className={s.Supplier}>{props.item.supplier.name}</div>
						)
					) : (
						<div className={s.NoSupplier}>&mdash;</div>
					)}
				</td>

				<td className={s.BtnGroup}>
					<button type="button" className={clsx(s.Btn, s.ConfirmBtn)} onClick={handlePurchased}>
						<SvgCheckmark />
					</button>
					{doubleCheckVisible ? (
						<button type="button" className={clsx(s.Btn, s.DoubleCheckBtn)} onClick={handleRemoved}>
							Opravdu?
						</button>
					) : (
						<button type="button" className={clsx(s.Btn, s.RemoveBtn)} onClick={doubleCheck}>
							<SvgCross />
						</button>
					)}
				</td>
			</tr>
		</>
	)
}
