import Link from 'next/link'
import React from 'react'
import type { ShoppingListItemResult } from '../../app/data/ShoppingItemFragment'
import { DashboardView } from '../const/DashboardView'
import type { SupplierResult } from '../data/SupplierFragment'
import { Filter } from './Filter'
import s from './ShoppingList.module.sass'
import { ShoppingListArticleByDate } from './ShoppingListArticleByDate'
import { ShoppingListArticleBySupplier } from './ShoppingListArticleBySupplier'
import { TableRowHeader } from './TableRowHeader'

export function ShoppingList(props: {
	items: ShoppingListItemResult[]
	view: DashboardView
	suppliers: SupplierResult[]
	activeSupplier: string
}) {
	return (
		<>
			<Filter suppliers={props.suppliers} activeSupplier={props.activeSupplier} />
			<div className={s.Container}>
				{props.items.length > 1 ? (
					<table className={s.Table}>
						{props.items && (
							<TableRowHeader
								first=""
								second="Množství"
								third={props.view === DashboardView.Supplier ? 'Deadline' : 'Dodavatel'}
								fourth="Koupeno?"
							/>
						)}
						<tbody className={s.TableBody}>
							{props.view === DashboardView.Supplier ? (
								<ShoppingListArticleBySupplier items={props.items} />
							) : (
								<ShoppingListArticleByDate items={props.items} />
							)}
						</tbody>
					</table>
				) : (
					<div
						style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', padding: 42 }}>
						Není tu nic.
					</div>
				)}
				<Link href="/shopping-history" passHref>
					<a className={s.Button}>Historie nákupů</a>
				</Link>
			</div>
		</>
	)
}
