import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import type { SupplierResult } from '../data/SupplierFragment'
import s from './Filter.module.sass'

export function Filter(props: { suppliers: SupplierResult[]; activeSupplier: string }) {
	return (
		<div className={s.RowWrapper}>
			<div className={s.Row}>
				<span className={s.MobileHidden}>Roztřídit:</span>
				<Link href="/" passHref>
					<a className={clsx(!props.activeSupplier && s.ActiveButton, s.Button)}>Deadline</a>
				</Link>
				{props.suppliers.map((supp) => (
					<Link key={supp.id} href={{ pathname: '/', query: { supplier: supp.id } }} passHref>
						<a className={clsx(props.activeSupplier === supp.id && s.ActiveButton, s.Button)}>
							{supp.name}
						</a>
					</Link>
				))}
			</div>
		</div>
	)
}
