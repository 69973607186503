import React, { ReactNode } from 'react'
import s from './ShoppingList.module.sass'

export function TableRowHeader(props: {
	first: ReactNode
	second: ReactNode
	third: ReactNode
	fourth: ReactNode
}) {
	return (
		<thead>
			<tr className={s.Header}>
				<td className={s.HeaderCell}>{props.first}</td>
				<td className={s.HeaderCell}>{props.second}</td>
				<td className={s.HeaderCell}>{props.third}</td>
				<td className={s.HeaderCell}>{props.fourth}</td>
			</tr>
		</thead>
	)
}
